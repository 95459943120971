<script setup lang="ts">
// vue
import {
  onBeforeMount,
  ref,
  watch
} from 'vue'

// types
import type { Nullable } from '@revolutionprep/types'

// utilities
import { capitalizeFirst } from '@revolutionprep/utils'

/**
 * types
 * ==================================================================
 */
interface InputAppendIcon {
  on: Nullable<string>
  off: Nullable<string>
}

/**
 * props
 * ==================================================================
 */
interface Props {
  vid: string
  rules?: string
  value?: string
  persist?: boolean
  type?: string
  appendIcon?: InputAppendIcon
}
const props = withDefaults(defineProps<Props>(), {
  rules: '',
  value: '',
  persist: false,
  type: 'text',
  appendIcon: () => {
    return {
      on: 'mdi-eye',
      off: 'mdi-eye-off'
    }
  }
})

/**
 * emitted events
 * ==================================================================
 */
const emit = defineEmits([
  'input',
  'input-update-value'
])

/**
 * state
 * ==================================================================
 */
const innerValue = ref('')
const showPassword = ref(false)

/**
 * watchers
 * ==================================================================
 */
watch(innerValue, (newValue: string) => {
  emit('input', newValue)
})

watch(() => props.value, (newValue: string) => {
  innerValue.value = newValue
})

/**
 * methods
 * ==================================================================
 */
function save (newValue: string) {
  if (props.persist) {
    emit('input-update-value', { [props.vid]: newValue })
  }
}

/**
 * lifecycle hooks
 * ==================================================================
 */
onBeforeMount(() => {
  if (props.value) {
    innerValue.value = props.value
  }
})
</script>

<template>
  <v-text-field
    v-model="innerValue"
    v-bind="$attrs"
    :label="String($attrs.label)"
    variant="outlined"
    hide-details
    density="compact"
    :color="$attrs.valid ? 'success' : ''"
    :type="showPassword ? 'text' : 'password'"
    :append-icon="showPassword ? appendIcon.on : appendIcon.off"
    @click:append="showPassword = !showPassword"
    @change="save"
  />
  <div
    v-if="$attrs['error-messages']"
    class="ps-2 mb-n3"
  >
    <span class="text-red">
      {{ capitalizeFirst(String($attrs['error-messages'])) }}
    </span>
  </div>
</template>
