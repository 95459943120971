<script setup lang="ts">
// vue
import { computed, ref } from 'vue'

// validation
import { object, string } from 'yup'

// types
import type { FetchError } from 'ofetch'

// utils
import { capitalizeFirst } from '@revolutionprep/utils'

// nuxt
import { useNuxtApp, useRouter, useRuntimeConfig } from '#app'

// pinia
import { storeToRefs } from 'pinia'

// components
import InputTextField from '../input/InputTextField.vue'
import InputPasswordField from '../input/InputPasswordField.vue'
import Spinner from '../spinner/Spinner.vue'

// composables + stores
import {
  useAnalytics,
  useErrorHandler,
  useForm,
  useOrganizationStore,
  useVeeValidate
} from '#imports'

/**
 * props
 * ==================================================================
 */
interface Props {
  defaultUsername?: string
  defaultPassword?: string
  redirectUrl?: string
}
const props = withDefaults(defineProps<Props>(), {
  defaultUsername: '',
  defaultPassword: '',
  redirectUrl: '/'
})

/**
 * nuxt app
 * ==================================================================
 */
const { $actor } = useNuxtApp()

/**
 * runtime config
 * ==================================================================
 */
const config = useRuntimeConfig()

/**
 * router
 * ==================================================================
 */
const router = useRouter()

/**
 * stores
 * ==================================================================
 */
const organizationStore = useOrganizationStore()
const { organizationInfo: company } = storeToRefs(organizationStore)

/**
 * state
 * ==================================================================
 */
const isProcessing = ref(false)

/**
 * analytics
 * ==================================================================
 */
const {
  doIdentify,
  doRegisterSuperProperty
} = useAnalytics(config.public)

/**
 * error handler
 * ==================================================================
 */
const { doHandleError } = useErrorHandler()

/**
 * validation
 * ==================================================================
 */
const { defineField, meta } = useForm({
  initialValues: {
    username: props.defaultUsername,
    password: props.defaultPassword
  },
  validationSchema: object({
    username: string().required().label('Username'),
    password: string().required().label('Password')
  })
})

const { vuetifyConfig } = useVeeValidate(meta)

const [username, usernameAttrs] = defineField('username', vuetifyConfig)
const [password, passwordAttrs] = defineField('password', vuetifyConfig)

/**
 * computed
 * ==================================================================
 */
const actor = computed(() => {
  return $actor.core.actor.value
})

const appName = computed(() => {
  return `${capitalizeFirst(config.public.appName)} Dashboard`
})

const isLoggedIn = computed(() => {
  return $actor.core.isLoggedIn.value
})

const isSpoofing = computed(() => {
  return Boolean($actor.core.spoofing.value)
})

/**
 * methods
 * ==================================================================
 */
async function login () {
  try {
    isProcessing.value = true
    const user = await $actor.core.doLogin({
      user: {
        login: String(username.value),
        password: String(password.value)
      }
    })

    // analytics
    if (user && actor) {
      doIdentify(user, actor.value)
      doRegisterSuperProperty({
        'Actor ID': user.actorId,
        'Actor Type': user.actorType,
        'App Name': appName.value,
        is_spoofing: isSpoofing.value
      })
    }
    // redirect
    if (isLoggedIn.value) {
      await router.push(props.redirectUrl)
    }
  } catch (error) {
    doHandleError(error as FetchError)
  } finally {
    isProcessing.value = false
  }
}
</script>

<template>
  <h1
    class="font-weight-bold text-grey-darken-3"
    data-test="company-name"
  >
    {{ company.name }}
  </h1>
  <p
    class="font-weight-light text-grey-darken-3 mb-4"
    data-test="company-tag-line"
  >
    {{ company.tagLine }}
  </p>
  <v-form @submit.prevent="login">
    <v-row>
      <v-col cols="12">
        <InputTextField
          id="input-username"
          v-model="username"
          v-bind="usernameAttrs"
          data-test="input-username"
          :form-validation="true"
          label="Username"
          name="Username"
          placeholder="Enter username"
          type="text"
          vid="username"
        />
      </v-col>
      <v-col cols="12">
        <InputPasswordField
          id="input-password"
          v-bind="passwordAttrs"
          v-model="password"
          data-test="input-password"
          :form-validation="true"
          label="Password"
          name="Password"
          placeholder="Enter password"
          type="password"
          vid="password"
        />
      </v-col>
      <v-col cols="12">
        <v-btn
          block
          color="primary"
          data-test="btn-submit"
          :disabled="!meta.valid || isProcessing"
          type="submit"
        >
          <Spinner v-if="isProcessing" />
          <span v-if="isProcessing">
            Logging in...
          </span>
          <span v-else>
            Log In
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
